import React, { Component } from "react";
import { Link } from "react-router-dom";

import Loader from './../pages/Loader';

import horizontalCardImg1 from './../assets/img/webp/horizontal-card-img-1.webp';
import horizontalCardImg2Bg from './../assets/img/webp/horizontal-card-img-2-bg.webp';
import horizontalCardImg2 from './../assets/img/webp/horizontal-card-img-2.webp';
import horizontalCardImg3 from './../assets/img/webp/horizontal-card-img-3.webp';
import $ from "jquery";
import about1 from "../assets/img/webp/about-1.webp";
import about2 from "../assets/img/webp/about-2.webp";
import horizontalCardImg10 from "../assets/img/webp/horizontal-card-img-10.webp";
import horizontalCardImg8 from "../assets/img/webp/horizontal-card-img-8.webp";
import AOS from "aos";



export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }



    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }


    render() {
        const {loader} = this.state;
        return (
            <>
                {
                    loader === true
                        ? <Loader />
                        : <main>
                            <section className="banner banner-6">
                                <div className="container" data-aos="fade-up">
                                    <h4 className="sub-title">Our Services</h4>
                                    <h1 className="main-title">Developing New Concepts</h1>
                                </div>
                            </section>
                            <section className="horizontal-card">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <img src={horizontalCardImg1} alt="" className="img-fluid"
                                                 data-aos="fade-up" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content" data-aos="fade-up">
                                                <h2 className="title">Trust A Hospitality Company With Experience Of Ensuring New
                                                    Ventures Are Successful</h2>
                                                <p>When it comes to taking your new concept from paper to bricks and mortar, there’s
                                                    no better hospitality company to call upon.</p>
                                                <p>Big Guns Hospitality has an enviable reputation of starting up and running
                                                    successful F&B-focused businesses. We know what it takes to ensure a profitable
                                                    and growth-ready venture. Because of this, we’ve been able to create a suite of
                                                    services that provide genuine value to any hospitality-based start up.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-v2">
                                <img src={horizontalCardImg2Bg} alt="" className="bg" />
                                <img src={horizontalCardImg2} alt="" className="right-side-img" />
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8 col-xl-6">
                                            <div className="content" data-aos="fade-up">
                                                <h1 className="main-title">Developing New Concepts</h1>
                                                <p className="mb-4">Our experience in the field of hospitality and F&B
                                                    ensures that your new venture will be equipped for any eventuality, and
                                                    well placed to run as smoothly as possible.
                                                    <br /> Big Guns Hospitality provides everything you need to bring your
                                                    exciting new concepts to life. We take care of everything required
                                                    to make any start up thrive.</p>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Premises</h3>
                                                        <ul>
                                                            <li>Architectural and interior design</li>
                                                            <li>Construction project management</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Marketing</h3>
                                                        <ul>
                                                            <li>Brand creation and development</li>
                                                            <li>Market positioning</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <h3 className="title">Operations</h3>
                                                        <ul>
                                                            <li>Complete turnkey operational development</li>
                                                            <li>Financial systems implementation</li>
                                                            <li>Ongoing asset management</li>
                                                            <li>Product and programming development</li>
                                                            <li>Proforma development</li>
                                                            <li>Culinary and beverage program development</li>
                                                            <li>Banquet, catering and special event sales</li>
                                                            <li>Hiring and staffing</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </section>
                            <section className="horizontal-v3">
                                <div className="container">
                                    <div className="content" data-aos="fade-up">
                                        <div className="row">
                                            <div className="col-lg-6 order-2 order-lg-1">
                                                <div className="d-flex align-items-center h-100">
                                                    <div className="inner-content">
                                                        <h1 className="main-title">Utilize A Successful Hospitality Company’s
                                                            Experience</h1>
                                                        <p>Our set of development services is designed to take as much stress out of
                                                            launching a new business as possible, while still guaranteeing the best
                                                            possible start. Our practical knowledge of running successful food and
                                                            beverage ventures means that we know what works and doesn’t work for
                                                            customers. By utilizing our expertise, you can save time and expense by
                                                            avoiding costly mistakes along the way.</p>
                                                        <Link to="/services" className="btn btn-dark btn-lg">BACK TO OUR SERVICES</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 order-1 order-lg-2">
                                                <img src={horizontalCardImg3} alt=""
                                                     className="img-fluid right-side-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                }
            </>
        );
    }
}