import React, { Component } from "react";
import { Link } from "react-router-dom";

import Loader from './../pages/Loader';

import horizontalCardImg7 from './../assets/img/webp/horizontal-card-img-7.webp';
import horizontalCardImg8 from './../assets/img/webp/horizontal-card-img-8.webp';
import horizontalCardImg2Bg from './../assets/img/webp/horizontal-card-img-2-bg.webp';
import horizontalCardImg9 from './../assets/img/webp/horizontal-card-img-9.webp';
import card1 from './../assets/img/webp/card-1-1.webp';
import $ from "jquery";
import horizontalCardImg1 from "../assets/img/webp/horizontal-card-img-1.webp";
import horizontalCardImg2 from "../assets/img/webp/horizontal-card-img-2.webp";
import horizontalCardImg3 from "../assets/img/webp/horizontal-card-img-3.webp";
import AOS from "aos";


export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }



    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }


    render() {
        const {loader} = this.state;
        return (
            <>
                {
                    loader === true
                        ? <Loader />
                        : <main>
                            <section className="banner banner-8">
                                <div className="container" data-aos="fade-up">
                                    <h4 className="sub-title">Our Services</h4>
                                    <h1 className="main-title">Optimizing Existing Operations</h1>
                                </div>
                            </section>
                            <section className="horizontal-card">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <img src={horizontalCardImg7} alt="" className="img-fluid"
                                                 data-aos="fade-up" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content" data-aos="fade-up">
                                                <h2 className="title">From Optimizing F<span class="span">&</span>B Management To Turning Around Faltering
                                                    Restaurants</h2>
                                                <p className="opacity-75">Utilizing a fresh pair of eyes to look over your
                                                    operations is always a good idea. Processes and systems that you’ve become
                                                    accustomed to can be difficult to evaluate critically when they’ve served you
                                                    well for so long</p>
                                                <p className="opacity-75">Newer, quicker, and more reliable processes become
                                                    available all the time however, and by failing to spot potential for positive
                                                    change, your business will find it much harder to experience meaningful
                                                    growth.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-card bg-black">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="content" data-aos="fade-up">
                                                <h2 className="title">Optimizing Existing Operations</h2>
                                                <h3 className="mb-4">Optimizing Operations In Long-Standing Businesses</h3>
                                                <p className="opacity-75">It’s tempting to sit back when a business consistently
                                                    makes steady profits, and not want to change things up too much. Unfortunately,
                                                    this can also mean that you’re missing out on more potential income by not
                                                    making your business the best it can be.</p>
                                                <p className="opacity-75">Our services and evaluations can help to identify areas in
                                                    your business that would benefit from change and execute them efficiently to
                                                    drive tangible growth.</p>
                                                <br />
                                                <h3 className="mb-4">Turning Around Businesses That Need Guidance</h3>
                                                <p className="opacity-75">The food and beverage industry can be a tough sector
                                                    to compete in. Our F&B management services help businesses to make their
                                                    processes as efficient and profitable as possible.</p>
                                                <p className="opacity-75">Big Guns Hospitality specializes in helping turn
                                                    around businesses. We measure our success by how successful our clients are,
                                                    so you can be assured that we’re just as invested in getting your business
                                                    back on track as you are.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 offset-lg-1">
                                            <img src={horizontalCardImg8} alt="" className="img-fluid"
                                                 data-aos="fade-up" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-v2 img-30">
                                <img src={horizontalCardImg2Bg} alt="" className="bg" />
                                <img src={horizontalCardImg9} alt="" className="right-side-img" />
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="content" data-aos="fade-up">
                                                <h1 className="main-title">Our Optimizing Existing Operations Services</h1>
                                                <p className="mb-4">Big Guns Hospitality offers an array of services
                                                    designed to get the absolute most out of your business. Here’s just some
                                                    of the ways in which we can help you - </p>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Marketing</h3>
                                                        <ul>
                                                            <li>Re-branding Conceptualization and Operational
                                                                Restructuring
                                                            </li>
                                                            <li>Brand Consultation and Conversion</li>
                                                            <li>Marketing Plans</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Finances</h3>
                                                        <ul>
                                                            <li>Financial Performance Review</li>
                                                            <li>Liability Assessment</li>
                                                            <li>Selective Asset Management</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Operations</h3>
                                                        <ul>
                                                            <li>Operational Consulting: Design, Functionality, Culinary,
                                                                Beverage, Management, Systems
                                                            </li>
                                                            <li>Systems Implementation</li>
                                                            <li>Asset & Project Management</li>
                                                            <li>Third-party Restaurant Operator Liaison - JVs, Leases,
                                                                Management Deals
                                                            </li>
                                                            <li>Event Management</li>
                                                            <li>Menu Engineering</li>
                                                            <li>Cocktail, Beer, and Wine List Development</li>
                                                            <li>Hotel Food Consulting</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h3 className="title">Day-To-Day Procedures</h3>
                                                        <ul>
                                                            <li>Due Diligence</li>
                                                            <li>Staffing and Hiring</li>
                                                            <li>Program Management</li>
                                                            <li>Sales Strategy</li>
                                                            <li>Human Resources</li>
                                                        </ul>
                                                        <h3 className="title">Buildings And Premises</h3>
                                                        <ul>
                                                            <li>Property Assessment</li>
                                                            <li>Expansions and Property Development</li>
                                                            <li>Kitchen and Bar Schematic Design; Equipment Layout and
                                                                Specification(s)
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-v3">
                                <div className="container">
                                    <div className="content">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="inner-content" data-aos="fade-up">
                                                    <p>Big Guns Hospitality can make your business run to its full potential. Our
                                                        experience in the industry has seen us work with a multitude of different
                                                        companies of various sizes, so there’s very few challenges we haven’t
                                                        already tackled.</p>
                                                    <h1 className="main-title mb-4">Get In Touch To Find Out How We Can Enhance Your
                                                        Current Operations.</h1>
                                                    <Link to="/services" className="btn btn-dark btn-lg">BACK TO OUR SERVICES</Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <img src={card1} alt="" className="img-fluid right-side-img"
                                                     data-aos="fade-up" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                }
            </>
        );
    }
}