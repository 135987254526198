import React, { Component } from "react";
import { Link } from "react-router-dom";

import Loader from './../pages/Loader';
import AOS from 'aos';

// import heroBanner from './../assets/img/webp/hero-banner.webp';
import videoDesktop from './../assets/img/desktop_video.mp4';
import desktopPoster from './../assets/img/webp/desktop-poster.webp';
import videoMobile from './../assets/img/mobile_video.mp4';
import mobilePoster from './../assets/img/webp/mobile-poster.webp';
import card1 from './../assets/img/webp/card1.webp';
import card2 from './../assets/img/webp/card2.webp';
import card3 from './../assets/img/webp/card3.webp';
import card4 from './../assets/img/webp/card4.webp';
import logo1 from './../assets/img/webp/logo1.webp';
import logo2 from './../assets/img/webp/logo2.webp';
import logo3 from './../assets/img/webp/logo3.webp';
import logo4 from './../assets/img/webp/logo4.webp';
import logo5 from './../assets/img/webp/logo5.webp';
import logo6 from './../assets/img/webp/logo6.webp';
import logo7 from './../assets/img/webp/bnp_paribas.webp';
import logo8 from './../assets/img/webp/logo8.webp';
import logo9 from './../assets/img/webp/logo9.webp';
import logo10 from './../assets/img/webp/logo10.webp';
import logo11 from './../assets/img/webp/w_hotel.webp';
import logo12 from './../assets/img/webp/logo12.webp';
import logo13 from './../assets/img/webp/logo13.webp';
import logo14 from './../assets/img/webp/logo14.webp';
import logo15 from './../assets/img/webp/logo15.webp';
import logo16 from './../assets/img/webp/logo16.webp';
import logo17 from './../assets/img/webp/logo17.webp';
import logo18 from './../assets/img/webp/logo18.webp';
import serviceCard1 from './../assets/img/webp/service-card-1.webp';
import serviceCard2 from './../assets/img/webp/service-card-2.webp';
import serviceCard3 from './../assets/img/webp/service-card-3.webp';
import formSideImg from './../assets/img/webp/form-side-img.webp';
import muteIcon from './../assets/img/png/mute.png';
import unMuteIcon from './../assets/img/png/unmute.png';
import PDFFile from './../assets/pdf/BGH Asset Management.pdf';
import $ from "jquery";


export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVideoLoaded: false,
            desktopMusic: true,
            mobileMusic: true,
        };
    }


    componentDidMount() {
        setTimeout(() => {
                $('.preloader').fadeOut();
            },
            3000,
        );
        setTimeout(() => {
                AOS.init();
            },
            3500,
        );
    }

    moveToTop() {
        window.scrollTo(0, 0);
    }

    desktopMusic = () => {
        this.setState(prevState => ({
            desktopMusic: !prevState.desktopMusic
        }));
    };
    mobileMusic = () => {
        this.setState(prevState => ({
            mobileMusic: !prevState.mobileMusic
        }));
    };

    render() {
        const {desktopMusic, mobileMusic} = this.state;
        return (
            <>
                <Loader />
                <main>
                <section className="hero">
                    <button className={'btn-music desktop d-none d-lg-block'} onClick={this.desktopMusic}>
                        {
                            desktopMusic
                            ? <img src={muteIcon} alt="" className={'icon un-mute-icon'}/>
                            :  <img src={unMuteIcon} alt="" className={'icon mute-icon'}/>
                        }
                    </button>
                    <button className={'btn-music mobile d-lg-none'} onClick={this.mobileMusic}>
                        {
                            mobileMusic
                                ? <img src={muteIcon} alt="" className={'icon un-mute-icon'}/>
                                :  <img src={unMuteIcon} alt="" className={'icon mute-icon'}/>
                        }
                    </button>
                    <video
                        playsInline
                        muted={desktopMusic}
                        autoPlay
                        loop
                        poster={desktopPoster}
                        className={'bg d-none d-lg-block'}
                    >
                        <source src={videoDesktop} type="video/mp4" />
                    </video>
                    <video
                        playsInline
                        muted={mobileMusic}
                        autoPlay
                        loop
                        poster={mobilePoster}
                        className={'bg d-lg-none'}
                    >
                        <source src={videoMobile} type="video/mp4" />
                    </video>
                    <div className="container">
                        <div className="caption" data-aos="fade-up">
                            <h1 className="title">A Restaurant and Hotel Management Company That Drives <span
                                className="fw-bold">REAL GROWTH</span></h1>
                        </div>
                    </div>
                </section>
                <section className="short-text">
                    <div className="container">
                        <div className="text-center" data-aos="fade-up">
                            <p className="mb-4">Big Guns Hospitality specializes in creating enterprise value for
                                businesses serving food and beverages. Whether it’s a hotel management company you need,
                                or help running a new restaurant, we’ve got you covered. With a wealth of experience in
                                the sector, we have the tools your business needs to get ahead of the competition.</p>
                            <Link to="/about" className="btn btn-dark btn-lg" onClick={this.moveToTop}>About Us</Link>
                        </div>
                    </div>
                </section>
                <section className="cards">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card" data-aos="fade-up">
                                    <img src={card1} alt="" className="card-img" />
                                        <div className="card-body">
                                            <h2 className="title">We Get Results - FAST</h2>
                                            <p>Working with a wide array of food and beverage focused businesses across
                                                all sectors, Big Guns Hospitality have the means to deliver prompt and
                                                reliable results whether you’re a fresh start-up restaurant, or
                                                multinational hotel chain.</p>
                                        </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card" data-aos="fade-up">
                                    <img src={card2} alt="" className="card-img" />
                                        <div className="card-body">
                                            <h2 className="title">Your Success Is Our Success</h2>
                                            <p>Our business relies on the success that we bring to our clients, and the
                                                references that they provide. This means that you can be assured that
                                                Big Guns Hospitality consistently strives to drive added value to your
                                                business and treat it with the same care and consideration as you would
                                                yourself.</p>
                                        </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card" data-aos="fade-up">
                                    <img src={card3} alt="" className="card-img" />
                                        <div className="card-body">
                                            <h2 className="title">An Essential For New Restaurant Ventures</h2>
                                            <p>We all know the risks inherent with opening up new restaurants, but by
                                                utilizing the skills and knowledge of a specialist team, you can ensure
                                                the success of your new venture. <br />
                                                    Put simply, we know what works. By working with you to provide
                                                    guidance on the layout and architecture of your premises, you can
                                                    give your new restaurant the best possible start. We can even help
                                                    with engineering your menus and wine lists to provide the best
                                                    possible experience for customers, and profitability for you.</p>
                                        </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card" data-aos="fade-up">
                                    <img src={card4} alt="" className="card-img" />
                                        <div className="card-body">
                                            <h2 className="title">Hotel Management Company</h2>
                                            <p>Overseeing the food and beverage side of hotel operations can be a
                                                monumental undertaking, especially when there’s already the sizable task
                                                of running a successful hotel to take care of. That’s why clients such
                                                as Marriott rely on us to take care of their food and drink operations
                                                in their flagship South Beach, Miami hotel. We can manage every aspect
                                                from staffing, to negotiating with suppliers. This ensures the servicing
                                                element of your hotel becomes a real feature for customers, and
                                                financial benefit for your business.</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="affiliated">
                    <div className="container">
                        <div className="text-center" data-aos="fade-up">
                            <h1 className="main-title mb-lg-5"><span
                                className="fw-bold">Affiliated With The Following</span> <span
                                className="fw-bold">Brands</span></h1>
                        </div>
                        <ul className="logos" data-aos="fade-up">
                            <li>
                                <img src={logo1} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo2} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo3} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo4} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo5} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo6} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo7} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo8} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo9} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo10} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo11} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo12} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo13} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo14} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo15} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo16} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo17} alt="" className="icon" />
                            </li>
                            <li>
                                <img src={logo18} alt="" className="icon" />
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="cards v2">
                    <div className="container">
                        <h1 className="main-title text-center mb-4 mb-lg-5 fw-bold" data-aos="fade-up">Our <span
                            className="fw-bold">Services</span></h1>
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="card" data-aos="fade-up">
                                            <img src={serviceCard1} alt="" className="card-img" />
                                                <div className="card-body">
                                                    <h2 className="title text-center">Developing New Concepts</h2>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card" data-aos="fade-up">
                                            <img src={serviceCard2} alt="" className="card-img" />
                                                <div className="card-body">
                                                    <h2 className="title text-center">Perfecting Existing Concepts </h2>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card" data-aos="fade-up">
                                            <img src={serviceCard3} alt="" className="card-img" />
                                                <div className="card-body">
                                                    <h2 className="title text-center">Optimizing Existing
                                                        Operations</h2>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-sm-8 col-md-9 col-lg-6 col-xl-5 col-xxl-4">
                                <div data-aos="fade-up mt-4">
                                    <Link to="/services" className="btn btn-light btn-lg mb-3 w-100" onClick={this.moveToTop}>Learn More</Link>
                                    <Link className="btn btn-light btn-sm w-100" onClick={() => window.open(PDFFile)}>Download our Latest Brochure</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="form-wrapper" id="contact">
                    <img src={formSideImg} alt="" className="side-img" />
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h4 className="title" data-aos="fade-up">Get In Touch To Discover How We Can Help
                                        You To Add Extra Value To Your Company.</h4>
                                    <form action="" data-aos="fade-up">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control"
                                                           placeholder="First Name *" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control"
                                                           placeholder="Last Name *" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Email *" />
                                        </div>
                                        <div className="form-group">
                                            <input type="phone" className="form-control" placeholder="Phone *" />
                                        </div>
                                        <div className="form-group">
                                            <textarea name="" id="" cols="30" rows="2" className="form-control"
                                                      placeholder="Message"></textarea>
                                        </div>
                                        <button type="submit" className="btn btn-dark btn-lg">Get In Touch</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                </section>
            </main>
            </>
        );
    }
}