import React, { Component } from "react";
import AOS from 'aos';
import Loader from './../pages/Loader';

import about1 from './../assets/img/webp/about-1.webp';
import about2 from './../assets/img/webp/about-2.webp';
import horizontalCardImg2Bg from './../assets/img/webp/horizontal-card-img-2-bg.webp';
import horizontalCardImg10 from './../assets/img/webp/horizontal-card-img-10.webp';
import horizontalCardImg8 from './../assets/img/webp/horizontal-card-img-8.webp';
import $ from "jquery";
import {Link} from "react-router-dom";



export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }



    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }




    render() {
        const {loader} = this.state;
        return (
            <>
                {
                    loader === true
                    ? <Loader />
                        : <main>
                            <section className="banner banner-about-us">
                                <div className="container" data-aos="fade-up">
                                    <h1 className="main-title text-center">About Us</h1>
                                </div>
                            </section>
                            <section className="short-text">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8 offset-lg-2">
                                            <div className="text-center" data-aos="fade-up">
                                                <h1 className="mb-4">An F<span className="span">&</span>B And Restaurant Management Company With The Experience To
                                                    Make A Difference</h1>
                                                <p className="mb-4 opacity-75">Big Guns Hospitality prides itself on the
                                                    professionalism and reliability that our clients have come to expect from us.
                                                    With our wealth of experience in the sector, we’re fast becoming the go-to
                                                    choice for businesses wanting a safe pair of hands to manage their food and
                                                    beverage based interests. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="cards">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="card border-0 rounded-0" data-aos="fade-up">
                                                <img src={about1} alt="" className="card-img h-auto" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card border-0 rounded-0" data-aos="fade-up">
                                                <img src={about2} alt="" className="card-img h-auto" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-v2">
                                <img src={horizontalCardImg2Bg} alt="" className="bg" />
                                <img src={horizontalCardImg10} alt="" className="right-side-img" />
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="content" data-aos="fade-up">
                                                <h1 className="main-title">Our Founder’s Background In Start-Ups, Turnaround
                                                    Companies, And F<span className="span">&</span>B Management</h1>
                                                <p className="opacity-75 mb-4">With over 30 years in venture capital,
                                                    investment banking and hospitality, John Guarnieri made his name helping
                                                    start ups, growth companies, and firms that needed to turn around their
                                                    fortunes.</p>
                                                <p className="opacity-75 mb-4">His success in this area complimented his
                                                    previous work at Compass Group (a $33 billion global provider of F&B
                                                    management services). During his time there, he managed several
                                                    world-class sporting and entertainment events and restaurants with full
                                                    fiduciary and operations responsibility.</p>
                                                <p className="opacity-75 mb-4">This experience lends him the unique ability
                                                    to see F&B businesses from an owner’s, investor’s and operator’s
                                                    perspective: a valuable ability in various scenarios. With this in mind,
                                                    he founded Big Guns Hospitality, where he and his team were able to
                                                    share this knowledge to F&B businesses nationwide.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="horizontal-card bg-black">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <img src={horizontalCardImg8} alt="" className="img-fluid"
                                                 data-aos="fade-up" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="content" data-aos="fade-up">
                                                <h2 className="title">An F<span className="span">&</span>B And Restaurant Management Company That Makes A
                                                    Difference</h2>
                                                <p className="opacity-75">Big Guns Hospitality has since grown to become the large
                                                    elite team of food and beverage experts that we know today. By sourcing the best
                                                    talent available in the sector, we’re able to ensure that businesses of all
                                                    sizes can realize their potential in the food and beverage sector.</p>
                                                <p className="opacity-75">With clients ranging from luxury hotels to independently
                                                    owned restaurants, Big Guns Hospitality is unparalleled in the experience and
                                                    expertise that it can offer. We pride ourselves on creating real value for our
                                                    clients, with our services scalable to any amount of growth.</p>
                                                <p className="opacity-75">In fact, we’ve been the trusted management team behind the
                                                    award-winning F&B outlets at Marriott’s luxury flagship W Hotel South Beach
                                                    premises for over the last decade.</p>
                                                <Link to="/services" className="btn btn-dark btn-lg mt-4" onClick={this.moveToTop}>Our Services</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                }
            </>
        );
    }
}