import React, { Component } from "react";
import { Link } from "react-router-dom";

import Loader from './../pages/Loader';

import serviceCard1 from './../assets/img/webp/service-card-1.webp';
import serviceCard2 from './../assets/img/webp/service-card-2.webp';
import serviceCard3 from './../assets/img/webp/service-card-3.webp';
import $ from "jquery";
import horizontalCardImg41 from "../assets/img/webp/horizontal-card-img-4-1.webp";
import horizontalCardImg2Bg from "../assets/img/webp/horizontal-card-img-2-bg.webp";
import horizontalCardImg5 from "../assets/img/webp/horizontal-card-img-5.webp";
import horizontalCardImg6 from "../assets/img/webp/horizontal-card-img-6.webp";
import AOS from "aos";



export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }

    moveToTop() {
        window.scrollTo(0, 0);
    }

    render() {
        const {loader} = this.state;
        return (
            <>
                {
                    loader === true
                        ? <Loader />
                        : <main>
                            <section className="banner banner-services">
                                <div className="container" data-aos="fade-up">
                                    <h1 className="main-title text-center">Our Services</h1>
                                </div>
                            </section>
                            <section className="short-text">
                                <div className="container">
                                    <div className="text-center" data-aos="fade-up">
                                        <h2 className="mb-4">A Comprehensive Suite Of Restaurant And Hotel <br /> Management Services
                                            To Benefit Any Business</h2>
                                        <p className="mb-4">No two businesses are the same, especially in the food and beverage
                                            sector. That’s why we offer a
                                            <br /> comprehensive range of services that provide real value to your company, regardless
                                            of size or situation.</p>
                                    </div>
                                </div>
                            </section>
                            <section className="cards v3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-10 offset-xl-1">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="card" data-aos="fade-up">
                                                        <img src={serviceCard1} alt="" className="card-img" />
                                                        <div className="card-body">
                                                            <h2 className="title text-center">Developing New Concepts</h2>
                                                            <p>Food and beverage is a competitive market, so it’s imperative
                                                                that your new venture hits the ground running. We ensure this by
                                                                making sure that every aspect from branding to construction is
                                                                perfect before you launch.</p>
                                                            <Link to="/developing-new-concepts"
                                                                  className="btn btn-dark btn-lg mt-4" onClick={this.moveToTop}>Learn More</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="card" data-aos="fade-up">
                                                        <img src={serviceCard2} alt="" className="card-img" />
                                                        <div className="card-body">
                                                            <h2 className="title text-center">Perfecting Existing Concepts </h2>
                                                            <p>Our expertise in the field can turn a good concept to a great
                                                                one. We provide a myriad of services such as CAPEX investment
                                                                strategies and leadership evaluations to help your business
                                                                concept realize its full potential. </p>
                                                            <Link to="/perfecting-existing-concepts"
                                                                  className="btn btn-dark btn-lg mt-4" onClick={this.moveToTop}>Learn More</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="card" data-aos="fade-up">
                                                        <img src={serviceCard3} alt="" className="card-img" />
                                                        <div className="card-body">
                                                            <h2 className="title text-center">Optimizing Existing
                                                                Operations</h2>
                                                            <p>Whether it’s honing and streamlining your company’s operations to
                                                                improve upon already good results or overhauling entire
                                                                processes to give new life to a struggling venture, we have the
                                                                restaurant and hotel management services to help. From
                                                                operational consulting to full management agreements, our
                                                                services can put your business back where you want it.</p>
                                                            <Link to="/optimizing-existing-operations"
                                                                  className="btn btn-dark btn-lg mt-4" onClick={this.moveToTop}>Learn More</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                }
            </>
        );
    }
}