import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Modal, Button} from 'react-bootstrap';

// Images ==================
import Logo from './../assets/img/webp/logo-dark.webp';
import pin from './../assets/img/png/pin.png';
import envelope from './../assets/img/png/envelope.png';
import phone from './../assets/img/png/phone.png';
import facebook from './../assets/img/webp/facebook.webp';
import instagram from './../assets/img/webp/instagram.webp';
import checkIcon from './../assets/img/webp/check-icon.webp';



export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            handleShow: false,
            handleClose: true
        };
    }

    handleClose() {
        this.setState({handleClose: false})
    }

    render() {
        const year = new Date().getFullYear();
        const {handleShow, handleClose} = this.state;
        return (
            <>
                <div className="footer">
                    <Link className="logo" to="/">
                        <img src={Logo} alt="Big Guns Hospitality" width="330" height="98" />
                    </Link>
                    <ul className="menu">
                        <li><img src={pin} alt="" className="icon" /> 1801 NE 123rd Street, Suite 314
                            North Miami FL 33181</li>
                        <li><img src={envelope} alt="" className="icon" /> <a
                            className="text-decoration-none text-black"
                            href="mailto:info@bghosp.com">info@bghosp.com</a></li>
                        <li><img src={phone} alt="" className="icon" /> <a
                            className="text-decoration-none text-black phone" href="tel:(786)244-4867">(786) 244-4867<br/><span>BIG-GUNS</span></a></li>
                    </ul>
                    <ul className="social d-none">
                        <li><a href="" target="_blank" aria-label="facebook"><img src={facebook} alt=""
                                                                                  width="15" height="28" /></a></li>
                        <li><a href="" target="_blank" aria-label="instagram"><img src={instagram}
                                                                                   alt="" width="28" height="28" /></a></li>
                    </ul>
                    <div className="bottom">
                        <p>© All Rights Reserved Big Guns Hospitality</p>
                    </div>
                </div>
                <Modal centered className={'thankYouPopup'} show={handleShow} onHide={handleClose}>
                    <Modal.Body>
                        <div className="text-end">
                            <button onClick={this.handleClose} type="button" className="btn-close"></button>
                        </div>
                        <div className="text-center">
                            <img src={checkIcon} alt="" className="icon" />
                                <h2 className="title">Thank you!</h2>
                                <p>One of our representatives will contact you shortly.</p>
                                <button type="button" className="btn btn-dark btn-lg">Ok</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}