import React from 'react';

import Logo from './../assets/img/logo.svg';

const Loader = () => {
    return (
        <div className="preloader loading">
            <img src={Logo} alt="" className="logo" />
                <div className="loading_wrapper">
                    <div className="loading_bar">
                        <span></span>
                    </div>
                </div>
        </div>
    );
};

export default Loader;