import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
// import './../assets/scss/component/header.scss';
// Images ==================
import Logo from './../assets/img/logo.svg';
import menu_border from './../assets/img/webp/menu-border.webp';
import $ from "jquery";

import 'aos/dist/aos.css';

export default class Header extends Component {

    componentDidMount() {
        var Header = $("header.header");
        function Header_Scroll(){
            var scroll = $(window).scrollTop();

            if (scroll >= $(Header).outerHeight()) {
                $(Header).addClass("scrolled");
            } else {
                $(Header).removeClass("scrolled");
            }
        }
        Header_Scroll();
        $(window).scroll(function() {
            Header_Scroll();
        });
    }

    load() {
        $('.navbar-toggler').click();
        window.scrollTo(0, 0);
    }
    menuCollapse() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <header className="header">
                    <Navbar expand="">
                        <div className="container">
                            <Link className="navbar-brand" to="/" onClick={this.menuCollapse}>
                                <img src={Logo} alt="" />
                            </Link>
                            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link className="nav-link active" aria-current="page" to="/" onClick={this.load}>Home</Link>
                                    </li>
                                    <NavDropdown title="Our Services" id="basic-nav-dropdown">
                                        <Link className={'dropdown-item'} to="/developing-new-concepts" onClick={this.load}>Developing New Concepts</Link>
                                        <Link className={'dropdown-item'} to="/perfecting-existing-concepts" onClick={this.load}>Perfecting Existing Concepts</Link>
                                        <Link className={'dropdown-item'} to="/optimizing-existing-operations" onClick={this.load}>Optimizing Existing Operations</Link>
                                    </NavDropdown>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/about" onClick={this.load}>About</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/contact" onClick={this.load}>Contact Us</Link>
                                    </li>
                                </ul>
                                <img src={menu_border} alt="" className="menu-img" />
                            </Navbar.Collapse>
                            <img src={menu_border} alt="" className="menu-img" />
                        </div>
                    </Navbar>
                </header>
            </>
        );
    }

}
