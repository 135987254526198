import React, { Component } from "react";

import Loader from './../pages/Loader';

import formSideImg2 from './../assets/img/webp/form-side-img-2.webp';
import $ from "jquery";
import AOS from "aos";
import about1 from "../assets/img/webp/about-1.webp";
import about2 from "../assets/img/webp/about-2.webp";
import horizontalCardImg2Bg from "../assets/img/webp/horizontal-card-img-2-bg.webp";
import horizontalCardImg10 from "../assets/img/webp/horizontal-card-img-10.webp";
import horizontalCardImg8 from "../assets/img/webp/horizontal-card-img-8.webp";


export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }



    componentDidMount() {
        setTimeout(() => {
                this.setState({loader: false});
                $('.preloader').fadeOut();
                AOS.init();
            },
            4000,
        );
    }


    render() {
        const {loader} = this.state;
        return (
            <>
                {
                    loader === true
                        ? <Loader />
                        : <main>
                            <section className="banner banner-contact">
                                <div className="container" data-aos="fade-up">
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1">
                                            <div className="text-center" data-aos="fade-up">
                                                <h1 className="main-title">Contact Us</h1>
                                                <p>Our experience allows us to get the best out of any business, regardless of size
                                                    and goal. Get in touch to find out how we can help your business or concept
                                                    specifically. Simply fill out the form below, and we’ll get back to you
                                                    soon. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="form-wrapper">
                                <img src={formSideImg2} alt="" className="side-img" />
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h4 className="title" data-aos="fade-up">Get In Touch To Discover How We Can Help
                                                You To Add Extra Value To Your Company.</h4>
                                            <form action="" data-aos="fade-up">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control"
                                                                   placeholder="First Name *" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control"
                                                                   placeholder="Last Name *" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input type="email" className="form-control" placeholder="Email *" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="phone" className="form-control" placeholder="Phone *" />
                                                </div>
                                                <div className="form-group">
                                            <textarea name="" id="" cols="30" rows="2" className="form-control"
                                                      placeholder="Message"></textarea>
                                                </div>
                                                <button type="submit" className="btn btn-dark btn-lg">Get In Touch</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                }
            </>
        );
    }
}